<template>
  <div class="container-fluid p-0 mb-5" id="pdcGrid">
    <div class="wrapper">
      <div class="item texto">
        <div>
          <img
            src="@/assets/images/politicasDeCalidad/politicasdecalidad-tilde.png"
            class="img-fluid h60"
          />
          <p>{{ $t("politicasDeCalidad.politicasDeCalidad.card_1") }}</p>
        </div>
      </div>
      <div class="item img">
        <img
          src="@/assets/images/politicasDeCalidad/01.webp"
          class="img-fluid"
        />
      </div>
      <div class="item texto">
        <div>
          <img
            src="@/assets/images/politicasDeCalidad/politicasdecalidad-tilde.png"
            class="img-fluid h60"
          />
          <p>{{ $t("politicasDeCalidad.politicasDeCalidad.card_2") }}</p>
        </div>
      </div>
      <div class="item texto">
        <div>
          <img
            src="@/assets/images/politicasDeCalidad/politicasdecalidad-tilde.png"
            class="img-fluid h60"
          />
          <p>{{ $t("politicasDeCalidad.politicasDeCalidad.card_3") }}</p>
        </div>
      </div>
      <div class="item texto">
        <div>
          <img
            src="@/assets/images/politicasDeCalidad/politicasdecalidad-tilde.png"
            class="img-fluid h60"
          />
          <p>{{ $t("politicasDeCalidad.politicasDeCalidad.card_4") }}</p>
        </div>
      </div>
      <div class="item img">
        <img
          src="@/assets/images/politicasDeCalidad/02.webp"
          class="img-fluid"
        />
      </div>
      <div class="item img">
        <img
          src="@/assets/images/politicasDeCalidad/03.webp"
          class="img-fluid"
        />
      </div>
      <div class="item texto">
        <div>
          <img
            src="@/assets/images/politicasDeCalidad/politicasdecalidad-tilde.png"
            class="img-fluid h60"
          />
          <p>{{ $t("politicasDeCalidad.politicasDeCalidad.card_5") }}</p>
        </div>
      </div>
      <div class="item texto">
        <div>
          <img
            src="@/assets/images/politicasDeCalidad/politicasdecalidad-tilde.png"
            class="img-fluid h60"
          />
          <p>{{ $t("politicasDeCalidad.politicasDeCalidad.card_6") }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PDC_Grid",
};
</script>
