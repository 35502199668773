<template>
  <div>
    <div ref="quienes-somos"></div>
    <QuienesSomos />

    <div ref="politicas-de-calidad"></div>
    <div class="container my-4">
      <h5>{{ $t("politicasDeCalidad.politicasDeCalidad.title") }}</h5>
    </div>
    <Grid />
  </div>
</template>

<script>
import PDC_QuienesSomos from "@/views/modules/PDC_QuienesSomos";
import PDC_Grid from "@/views/modules/PDC_Grid";
export default {
  name: "PoliticasDeCalidad",
  components: {
    QuienesSomos: PDC_QuienesSomos,
    Grid: PDC_Grid,
  },
  data() {
    return {
      hash: this.$route.hash,
    };
  },
  mounted() {
    this.$nextTick(function () {
      if (this.hash) {
        const refName = this.hash.replace("#", "");
        this.scrollToAnchorPoint(refName);
      }
    });
  },
  methods: {
    scrollToAnchorPoint(refName) {
      const el = this.$refs[refName];
      el.scrollIntoView({ behavior: "smooth" });
    },
  },
};
</script>