<template>
  <div class="pb-2" id="pdcQuienesSomos">
    <div class="nuestros-valores bg-gris-claro">
      <div class="container">
        <div class="row d-flex align-items-center justify-content-start">
          <div class="col-md-6">
            <div class="card my-5">
              <h5>{{ $t("politicasDeCalidad.quienesSomos.title") }}</h5>
              <p class="mb-4">
                {{ $t("politicasDeCalidad.quienesSomos.texto") }} .
              </p>

              <h5>{{ $t("politicasDeCalidad.nuestrosValores.title") }}</h5>
              <ul>
                <li>{{ $t("politicasDeCalidad.nuestrosValores.b1") }}</li>
                <li>{{ $t("politicasDeCalidad.nuestrosValores.b2") }}</li>
                <li>{{ $t("politicasDeCalidad.nuestrosValores.b3") }}</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mision-vision bg-gris-claro">
      <div class="container">
        <div class="row d-flex align-items-center justify-content-start">
          <div class="col-md-6 offset-md-6">
            <div class="card my-5">
              <h5>{{ $t("politicasDeCalidad.mision.title") }}</h5>
              <p class="mb-4">{{ $t("politicasDeCalidad.mision.texto") }}</p>

              <h5>{{ $t("politicasDeCalidad.vision.title") }}</h5>
              <p>{{ $t("politicasDeCalidad.vision.texto") }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PDC_QuieneSomos",
};
</script>
